import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  name = 'Angular';
  public isCollapsed = true;
  constructor(private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.route.queryParams
       .subscribe(params => {
        if(Object.keys(params).length > 0){
          this.scroll(params.route);
        }
});
  }
  scroll(id){
    let el = document.getElementById(id);
    document.getElementById('navbarColor03').classList.remove("show");
    el.scrollIntoView({ behavior: 'smooth' });
  }
}
