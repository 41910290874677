<div class="bg-dark d-flex justify-content-between px-4 flex-wrap">
    <div class="d-flex ">
        <span id="boot-icon" class="bi bi-telephone-fill" ></span><a href="tel:++91 80988 99724" class="text-light px-2 m-auto">+91 80988 99724</a>

    </div>    
    <div class="d-flex">
        <span id="boot-icon" class="bi bi-envelope-fill" ></span><a class="text-light px-2 m-auto" href="mailto:pudumadamblooddonors@gmail.com">pudumadamblooddonors@gmail.com</a>

    </div>     
</div>
<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" href="#"><img class="" style="width: 70px;"  src="../../assets/images/logo.svg"
    alt="  Logo"></a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03" aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarColor03" >
    <ul class="navbar-nav mr-auto ml-auto">
      <li class="nav-item active">
        <a class="nav-link" href="">Home </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="scroll('whoWeAre')">Who We Are</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="scroll('whatWeDo')">What We Do</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="scroll('faq')">FAQ</a>
      </li>
      <li class="nav-item">
        <a class="nav-link reg-button" (click)="scroll('register')">Register as a Donor</a>
      </li>
    </ul>
    <div><!-- HTML !-->
        <a class="button-28" (click)="scroll('register')">Register as a Donor</a>
        
        </div>
  </div>
</nav>
<div class="bg-dark"><div class="bg-dark bt-line">
</div>

</div>
