<div class=" p-3 d-flex justify-content-center flex-column align-items-center">
    <div class="follow">Follow Us </div>
    <div>
        <a class="mx-2" href="https://www.instagram.com/pdm_blooddonors/"><i class="fab fa-instagram fa-lg" style="color: #999;"></i></a>
        <a class="mr-2" href="https://www.facebook.com/people/Pudumadam-blood-donors-Ramanathapuram/100064814530154/"><i class="fab fa-facebook-f fa-lg" style="color: #999;"></i></a>
    </div>
    

</div>
<div class="bg-dark"><div class="bt-line">
</div></div>
<div class="p-3 d-flex  flex-wrap justify-content-between">
   
    <div class="d-flex flex-wrap">
        <a  class="orgName mx-2"(click)="open(mymodal1)">Terms</a>
            <a class="orgName mx-2" (click)="open(mymodal)">Privacy</a>
            <div class="d-flex">
                <i class="far fa-copyright fa-xs" style="color:#000; display: flex;
                align-items: center;"></i>
                <div class="orgName">Pudumadam Blood Donors Designed by <a style="color:blue" href="https://linktr.ee/yasaratcoding">Yasar</a></div>
                
            </div>
     
            
        

    </div>


</div>

<ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Privacy Policy</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <p>This Privacy Policy (&quot;Policy&quot;) explains how we collect, use, disclose, and protect the personal information you provide when using our blood details registration web application (referred to as the &quot;Web Application&quot;). We are committed to protecting your privacy and handling your personal information in accordance with applicable privacy laws. By using the Web Application, you consent to the practices described in this Policy.</p>
        <ol>
            <li>
                <p>Information We Collect: a. Personal Information: When you register your blood-related details as a guest on the Web Application, we may collect personal information such as your name, contact information, blood type, and any additional details you provide voluntarily. b. Non-Personal Information: We may also collect non-personal information, such as demographic data, IP addresses, browser type, and usage statistics, to enhance the functionality and performance of the Web Application.</p>
            </li>
            <li>
                <p>Use of Information: a. The personal information you provide is used for the purpose of facilitating blood donations and matching potential donors with recipients. b. We may use your contact information to communicate with you regarding blood donation opportunities or to verify the accuracy of the details you have provided. c. We may use non-personal information for analytical and statistical purposes to improve the Web Application&apos;s performance, functionality, and user experience.</p>
            </li>
            <li>
                <p>Data Sharing and Disclosure: a. We may share your personal information with trusted third parties, such as blood banks, medical facilities, or individuals involved in the process of facilitating blood donations. b. We may disclose personal information when required by law or if we believe such disclosure is necessary to protect our rights, comply with a judicial proceeding, court order, or legal process served on us.</p>
            </li>
            <li>
                <p>Data Security: a. We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. b. Despite our efforts to protect your personal information, please note that no method of transmission over the internet or electronic storage is completely secure. We cannot guarantee absolute security of your data.</p>
            </li>
            <li>
                <p>Third-Party Links: a. The Web Application may contain links to third-party websites or services. We do not endorse or assume any responsibility for the content, privacy policies, or practices of such third-party websites or services. We encourage you to review the privacy policies of any third-party websites or services before providing any personal information.</p>
            </li>
            <li>
                <p>Children&apos;s Privacy: a. The Web Application is not intended for individuals under the age of 18. We do not knowingly collect personal information from individuals under this age. If we become aware that we have collected personal information from a child without parental consent, we will take steps to remove that information from our servers.</p>
            </li>
            <li>
                <p>Changes to the Privacy Policy: a. We reserve the right to update or modify this Privacy Policy at any time without prior notice. Any changes will be effective immediately upon posting the revised Policy on the Web Application. b. Your continued use of the Web Application after any changes to the Policy constitutes your acceptance of the revised Policy.</p>
            </li>
            <li>
                <p>Contact Us: If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at pudumadamblooddonors@gmail.com.</p>
            </li>
        </ol>
        <p>By using the Web Application, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
    </div>
  </ng-template>


  <ng-template #mymodal1 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Privacy Policy</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <p>Please read these Terms and Conditions (&quot;Terms&quot;) carefully before using our blood details registration web application (referred to as the &quot;Web Application&quot;). These Terms govern your access to and use of the Web Application and its services. By using the Web Application, you agree to be bound by these Terms. If you do not agree with any part of these Terms, you may not use the Web Application.</p>
<ol>
    <li>
        <p>Accuracy of Information: a. The Web Application allows users to register their blood-related details as a guest. You agree to provide accurate, current, and complete information during the registration process. b. You understand and acknowledge that the accuracy of the information provided is essential for facilitating blood donation and matching potential donors with recipients.</p>
    </li>
    <li>
        <p>Privacy and Data Protection: a. We are committed to protecting your privacy and handling your personal information in accordance with applicable privacy laws. Please refer to our Privacy Policy for details on how we collect, use, and disclose your personal information.</p>
    </li>
    <li>
        <p>Use of Information: a. By registering your blood-related details on the Web Application, you give consent for the information provided to be used for the purpose of facilitating blood donations and matching potential donors with recipients. b. You understand and acknowledge that the Web Application administrators may contact you based on the information provided to verify the accuracy of the details or to communicate relevant blood donation opportunities.</p>
    </li>
    <li>
        <p>User Obligations: a. You agree to use the Web Application solely for the purpose of registering your blood-related details and contributing to the potential availability of blood for those in need. b. You agree not to use the Web Application for any unlawful or unauthorized purpose.</p>
    </li>
    <li>
        <p>Limitation of Liability: a. In no event shall we be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with the use of the Web Application. b. Our total liability to you for any claims arising from or related to the Web Application shall be limited to the extent permitted by applicable laws.</p>
    </li>
    <li>
        <p>Third-Party Links and Services: a. The Web Application may contain links to third-party websites or services. We do not endorse or assume any responsibility for the content, privacy policies, or practices of such third-party websites or services. b. Your interactions with any third-party website or service are solely between you and the third party. We encourage you to review the terms and conditions and privacy policies of any third-party websites or services that you visit.</p>
    </li>
    <li>
        <p>Disclaimer: a. The Web Application is provided on an &quot;as is&quot; and &quot;as available&quot; basis without warranties of any kind, whether express or implied. b. We do not guarantee the accuracy, reliability, or completeness of any information provided through the Web Application.</p>
    </li>
    <li>
        <p>Modifications: a. We reserve the right to modify or revise these Terms at any time without prior notice. By continuing to use the Web Application after any modifications or revisions, you agree to be bound by the updated Terms.</p>
    </li>
    <li>
        <p>Governing Law and Jurisdiction: a. These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of [Jurisdiction].</p>
    </li>
    <li>
        <p>Severability: a. If any provision of these Terms is found to be unlawful, void, or unenforceable, the remaining provisions shall continue to be valid and enforceable to the fullest extent permitted by law.</p>
    </li>
</ol>
<p>By using the Web Application, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</p>
    </div>
  </ng-template>