import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import {Subject} from 'rxjs'; 
import { AngularFireAuth } from "@angular/fire/auth";

@Injectable({
  providedIn: 'root'
})
export class FirestoreDBService {
  invokeEvent: Subject<any> = new Subject(); 

  constructor(private firestore: AngularFirestore,private angularFireAuth: AngularFireAuth) { }

  getData(dbName:string,conditionFieldName:string,conditionFieldValue:string){
    if(conditionFieldName != null){
      return new Promise<any>((resolve)=> {
        this.firestore.collection(dbName, ref => ref.where(conditionFieldName, '==', conditionFieldValue)).snapshotChanges().subscribe(resp => resolve(resp))
      })
    }else{
      return new Promise<any>((resolve)=> {
        this.firestore.collection(dbName).snapshotChanges().subscribe(resp => {resolve(resp)},error =>{console.log(error)})
      })    
    }
  }
  create(dbName:string,value:object): any{
    return this.firestore.collection(dbName).add(value);
  }

  update(dbName:string,id: string, data: any): Promise<void> {
    return this.firestore.doc(dbName+'/'+id).update(data);
  }
  emit(){
    this.invokeEvent.next();   
  }

  /* Sign up */
 SignUp(email: string, password: string) {
  this.angularFireAuth
  .createUserWithEmailAndPassword(email, password)
  .then(res => {
  console.log('You are Successfully signed up!', res);
  })
  .catch(error => {
  console.log('Something is wrong:', error.message);
  });
  }
  
  /* Sign in */
  SignIn(email: string, password: string): any {
  return this.angularFireAuth
  .signInWithEmailAndPassword(email, password)

  }
  
  /* Sign out */
  SignOut() {
  this.angularFireAuth
  .signOut();
  }
  isloggedin():any{
     this.angularFireAuth.authState.subscribe(res=>{
      console.log(res)
      if(res){
        return true;
      }
      return false;
    })
      
     
  }
}
