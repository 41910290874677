<app-header>
</app-header>
<div class="bg-dark">
    <div class="hero-title ">
        <h1 class="mx-auto text-center">Donate Blood<br><span>And Be the reason to someone's existence</span></h1>
    </div>
    <div class="d-flex flex-column align-items-center">
        <app-running-number [digit]="totalDonorList" [duration]="1000"></app-running-number>
        <div class="sub-head">number of donors list.</div>
        <app-running-number [digit]="totalLivesSaved" [duration]="1000"></app-running-number>
        <div class="sub-head">number of lives we saved.</div>

    </div>
    <app-who-we-are id="whoWeAre"></app-who-we-are>
    <app-what-we-do id="whatWeDo"></app-what-we-do>
    <app-register id="register"></app-register>
    <app-faq id="faq"></app-faq>
    <app-footer></app-footer>
</div>