import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthguardGuard,LoginGuard } from './guard/authguard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch:'full'
 },  {
  path: 'home', component: HomeComponent 
},  {
  path: 'login', component: LoginComponent ,canActivate:[LoginGuard]
},{
  path:'dashboard',
  component:DashboardComponent,
  canActivate:[AuthguardGuard]
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
