import { Component, OnInit } from '@angular/core';
import{FirestoreDBService} from 'src/app/services/firestore-db.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  constructor(private firestoreDBservice:FirestoreDBService) { }

  ngOnInit(): void {
     this.getContactData();

  }
  async getContactData(){
        let cont = await this.firestoreDBservice.getData('Contact',null,null);
    console.log(cont)
  }

}
