import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import{FirestoreDBService} from 'src/app/services/firestore-db.service'
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  companyName='Pudumadam blood donors';
  loadingText = 'textLoad 2s linear infinite';
  ipData;
  showLoader = false;
  submitted = false;
  existingEmailError = false;
  existingPhoneError=false;
  zipCodeError = false;
  zipData;
  date = new Date();  
  maxDate = (new Date().getFullYear()).toString()+"-0"+(new Date().getMonth()+1).toString()+"-"+(new Date().getDate()).toString();
  dateError = false;
  bloodGroups = ['A+','A-','B+','B-','O+','O-','AB+','AB-'];
  constructor(
    private fb: FormBuilder,
    private http: HttpClient  ,private firestoreDBservice:FirestoreDBService) { }

    //on changing function for some of field in form
    debug(event){
      if(event.target.name == 'ZIP' || event.target.name == 'phone' ){
        event.target.value = Number.isInteger(+event.target.value) ? (event.target.value) : (event.target.value).slice(0, -1);
      }
      if(event.target.name == 'ZIP' ){
        if((event.target.value).length == 6){
          this.getZipData(event.target.value);
        }else{
          document.getElementById('place').innerHTML = '';
        }
      }else if(event.target.name == 'email' || event.target.name == 'phone' ){
        this.existingEmailError = false;
        this.existingPhoneError = false;
      }

    }

    //To get the ZIP code data
    getZipData(value){
      this.loadingText = 'textLoad 2s linear infinite';
      this.zipCodeError = false;
      this.showLoader = true;
      var code = value
      this.http.get<any>('https://api.postalpincode.in/pincode/'+code).subscribe(data => {
        this.showLoader = false;
        if(data[0].Status == 'Success'){
          this.zipData = data;
          var dt = data[0].PostOffice[0].District+','+data[0].PostOffice[0].State;

          document.getElementById('place').innerHTML = dt;

        }else{
          this.zipCodeError = true;
          document.getElementById('place').innerHTML = '';

        }
      });
    }

    get submitButtonValidation(){
      return (this.zipCodeError || !this.registerForm.valid || this.dateError || this.existingEmailError);
    }
    async updateDonorsCount(){
     
      let data =await this.firestoreDBservice.getData('Company_Settings','companyName',this.companyName);
      if(data.length == 1){
        let value = {totalDonorList:data[0].payload.doc.data().totalDonorList+1};
        console.log(value)
        console.log(data[0].payload.doc.id)
        this.firestoreDBservice.update('Company_Settings',data[0].payload.doc.id,value)
        .then(() => {
          this.firestoreDBservice.emit();
        })
        .catch(err => console.log(err));
      } 
      
    }
  ngOnInit() {

    //Grouping the form
    this.registerForm = this.fb.group({
      name: ['', [Validators.required,Validators.pattern("^[a-zA-Z\\s]*$")]],
      email: ['', [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
      dob: ['', [Validators.required]],
      bloodType:['',[Validators.required]],
      phone: ['', [Validators.required, Validators.pattern("[0-9 ]{10}")]],
      zipCode:['', [Validators.required, Validators.pattern("[0-9 ]{6}")]]
    });
    //Getting IP details
    var url = "https://api.db-ip.com/v2/free/self";
    this.http.get<any>(url).subscribe(data => {
               this.ipData = data;

    });
  }

  //FormControl for the form
  get registerFormControl() {
    return this.registerForm.controls;
  }

  dateChange(event){
    this.dateError = new Date(event) > new Date();
  }

  async onSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
      return;
    }
    this.loadingText = 'saveLoad 2s linear infinite';
    this.registerForm.value.ipAddress = this.ipData.ipAddress;
    this.registerForm.value.ipCity = this.ipData.city;
    this.registerForm.value.ipCountryName = this.ipData.countryName;
    this.registerForm.value.ipState = this.ipData.stateProv;
    this.registerForm.value.continentName = this.ipData.continentName;
    this.registerForm.value.createdDate = new Date().toLocaleString();
    this.registerForm.value.lastModifiedDate = new Date().toLocaleString();
    this.registerForm.value.district = this.zipData[0].PostOffice[0].District;
    this.registerForm.value.state = this.zipData[0].PostOffice[0].State;
    this.submitted = true;
    if (this.registerForm.valid && !this.dateError && !this.zipCodeError) {
      this.showLoader = true;
      let emailCheck = await this.firestoreDBservice.getData('Lead','email',this.registerForm.value.email);
      console.log(emailCheck)
      if(emailCheck.length > 0){
        this.showLoader = false;
        this.existingEmailError = true;
       return alert('This Email id is already resgistered.Please try with other Email id');
      }
      let dt = await this.firestoreDBservice.getData('Lead','phone',this.registerForm.value.phone);
      if(dt.length > 0){
        this.showLoader = false;
        this.existingPhoneError = true;
       return alert('This Phone number is already resgistered.Please try with other Phone number');
      }
      this.firestoreDBservice.create('Contact',this.registerForm.value)
        .then(async res => {
          console.log(res.path)
          var lead={phone:this.registerForm.value.phone,email:this.registerForm.value.email,contactId:res.path};
          this.firestoreDBservice.create('Lead',lead);
          this.showLoader = false;
          this.resetForm();
          alert('Form Submitted succesfully!!!\n Will reach you shortly as needed.');
          this.updateDonorsCount();
        })
        .catch(e => {
            this.showLoader = false;
            alert('Please try again later.');
        })
    }

  }

  resetForm(){
    document.getElementById('place').innerHTML = '';
    this.registerForm.reset();
    this.submitted = false;
  }
}
