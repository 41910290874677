import { Component, OnInit } from '@angular/core';
import{FirestoreDBService} from 'src/app/services/firestore-db.service'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers:[FirestoreDBService]
})
export class HomeComponent implements OnInit {
  totalLivesSaved = 500;
  totalDonorList = 550;
  companyName='Pudumadam blood donors';
  constructor(private firestoreDBservice:FirestoreDBService) {
    this.firestoreDBservice.invokeEvent.subscribe(value => {
       this.checkData(); 
   })}

  ngOnInit(): void {
   this.checkData();
   
  }
  async checkData(){
    let data =await this.firestoreDBservice.getData('Company_Settings','companyName',this.companyName);
    if(data.length == 1 ){
      this.totalDonorList = data[0].payload.doc.data().totalDonorList;
      this.totalLivesSaved = data[0].payload.doc.data().totalLivesSaved;
    } 

    console.log('done')

  }



}
