<div class="px-3 d-flex justify-content-center flex-wrap">
    <div class="big-title hide-for-mobile">WHO <br>WE<br>ARE<span>?</span>
    </div>
    <div class="big-title hide-for-desktop">WHO WE ARE<span>?</span>
    </div>

    <div>
        <div class="timeline">
            <div class="outer">
              <div class="card">
                <div class="info">
                  <h3 class="title fade-in-text ">Started on 16-09-2020</h3>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title fade-in-text ">Officially Registered on 02-03-2022</h3>
                </div>
              </div>
              <div class="card">
                <div class="info">
                  <h3 class="title fade-in-text ">Our official website has been launched on 29-06-2023</h3>
                </div>
              </div>
            </div>
          </div>
    </div>
</div>
<div class="px-3">
    <p class="text-center font-weight-bold content-text">From being an idea to help out mid-pandemic, we've grown into something much bigger. Founded by a group of young people, we strive to go beyond expectations.</p>
    <br><p class=" text-center content-text">Pudumadam Blood Donors Trust (PBD Reg.No:4/12/2022) is a non-governmental organization registered at Ramanathapuram, Tamilnadu, India in 2020 with the mission of fighting blood shortage.</p>
    <br><p class=" text-center content-text">PBD is a thriving community of volunteers, donors, and supporters united by a shared vision-to ensure a constant and accessible supply of safe blood for those in need. As part of our commitment, we create awareness, organize donation drives, and provide support to both donors and recipients.</p>
    <br><p class=" text-center content-text">At PBD, we believe that every drop of blood donated brings hope, healing, and the potential for a brighter future. Join us on this incredible journey as we work towards creating a world where no one suffers due to a shortage of blood.</p>
</div>
