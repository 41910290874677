<div class="p-3 d-flex justify-content-center flex-wrap">

    <div class="big-title ">WHAT WE DO<span>?</span>
    </div>
    <div class="content-text">
        At PBD, we understand the importance of arranging blood for donors, and we strive to make the process as efficient and reliable as possible. It may seem easy, but there are several crucial steps we undertake to ensure successful blood arrangements. <br>
        At PBD, we are committed to ensuring that the blood arrangement process is handled with care, precision, and constant communication. We prioritize accuracy, efficiency, and donor satisfaction to contribute to the successful outcome of every blood donation.
        <br>Here's an overview of our process:
        
    </div>

</div>





<div class="ag-timeline-block">

    <section class="ag-section">
      <div class="ag-format-container">
        <div class="js-timeline ag-timeline">
          <div class="js-timeline_line ag-timeline_line">
            <div class="js-timeline_line-progress ag-timeline_line-progress"></div>
          </div>
          <div class="ag-timeline_list">
            <div class="js-timeline_item ag-timeline_item">
              <div class="ag-timeline-card_box">
                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                  <div class="ag-timeline-card_point"></div>
                </div>
                <div class="ag-timeline-card_meta-box">
                  <div class="ag-timeline-card_meta">Accurate Information Collection</div>
                </div>
              </div>
              <div class="ag-timeline-card_item">
                <div class="ag-timeline-card_inner">
               
                  <div class="ag-timeline-card_info">
                    <div class="ag-timeline-card_title">Accurate Information Collection</div>
                    <div class="ag-timeline-card_desc">
                        When we receive a request for blood, our first priority is to verify the accuracy of the information provided. We collect essential details such as the requester's name, location, the required number of blood units, and the deadline for the arrangement. This step ensures that we have the correct information to proceed.
                    </div>
                  </div>
                </div>
                <div class="ag-timeline-card_arrow"></div>
              </div>
            </div>
  
            <div class="js-timeline_item ag-timeline_item">
              <div class="ag-timeline-card_box">
                <div class="ag-timeline-card_meta-box">
                  <div class="ag-timeline-card_meta">Forwarding Template and Community Engagement</div>
                </div>
                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                  <div class="ag-timeline-card_point"></div>
                </div>
              </div>
              <div class="ag-timeline-card_item">
                <div class="ag-timeline-card_inner">
                  <div class="ag-timeline-card_info">
                    <div class="ag-timeline-card_title">Forwarding Template and Community Engagement:</div>
                    <div class="ag-timeline-card_desc">
                        After verifying the request, we create a forwarding template that contains the necessary details. We then share this template with our community of donors and supporters. By involving our network, we increase the chances of finding a suitable donor quickly and efficiently.
                    </div>
                  </div>
                </div>
                <div class="ag-timeline-card_arrow"></div>
              </div>
            </div>
  
            <div class="js-timeline_item ag-timeline_item">
              <div class="ag-timeline-card_box">
                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                  <div class="ag-timeline-card_point"></div>
                </div>
                <div class="ag-timeline-card_meta-box">
                  <div class="ag-timeline-card_meta">Donor Database Check and Availability Confirmation</div>
                </div>
              </div>
              <div class="ag-timeline-card_item">
                <div class="ag-timeline-card_inner">
                  <div class="ag-timeline-card_info">
                    <div class="ag-timeline-card_title">Donor Database Check and Availability Confirmation</div>
                    <div class="ag-timeline-card_desc">
                        To identify potential donors, we thoroughly search our donor database using the provided information. We cross-reference the details with our records to find individuals who match the required blood type and are eligible for donation. Once potential donors are identified, we contact them to confirm their availability and willingness to donate.
                    </div>
                  </div>
                </div>
                <div class="ag-timeline-card_arrow"></div>
              </div>
            </div>
  
            <div class="js-timeline_item ag-timeline_item">
              <div class="ag-timeline-card_box">
                <div class="ag-timeline-card_meta-box">
                  <div class="ag-timeline-card_meta">Bridging the Donor with the Recipient</div>
                </div>
                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                  <div class="ag-timeline-card_point"></div>
                </div>
              </div>
              <div class="ag-timeline-card_item">
                <div class="ag-timeline-card_inner">
                  <div class="ag-timeline-card_info">
                    <div class="ag-timeline-card_title">Bridging the Donor with the Recipient</div>
                    <div class="ag-timeline-card_desc">
                        Once a suitable donor is found and confirmed, we act as a bridge between the donor and the recipient. We facilitate communication and arrange for the necessary logistics, ensuring a seamless connection between the two parties. Our goal is to streamline the process and ensure a smooth experience for both the donor and the recipient.
                    </div>
                  </div>
                </div>
                <div class="ag-timeline-card_arrow"></div>
              </div>
            </div>
  
            <div class="js-timeline_item ag-timeline_item">
              <div class="ag-timeline-card_box">
                <div class="js-timeline-card_point-box ag-timeline-card_point-box">
                  <div class="ag-timeline-card_point"></div>
                </div>
                <div class="ag-timeline-card_meta-box">
                  <div class="ag-timeline-card_meta">Continuous Follow-up</div>
                </div>
              </div>
              <div class="ag-timeline-card_item">
                <div class="ag-timeline-card_inner">
                  <div class="ag-timeline-card_info">
                    <div class="ag-timeline-card_title">Continuous Follow-up</div>
                    <div class="ag-timeline-card_desc">
                      Throughout the entire process, we maintain regular contact with the donor. We provide continuous support and follow-up to ensure their commitment to the donation. We address any concerns or questions they may have and provide assistance as needed. Our dedication extends until the donor safely completes their donation and returns.
                    </div>
                  </div>
                </div>
                <div class="ag-timeline-card_arrow"></div>
              </div>
            </div>
  
          </div>
        </div>
      </div>
    </section>
  </div>
 
