export const environment = {
  production: true,
  firebaseConfig :{
    apiKey: "AIzaSyDqZpld4BwZqfvElA2D7cEdYIkSMWQNcQA",
    authDomain: "pbd-v1.firebaseapp.com",
    projectId: "pbd-v1",
    storageBucket: "pbd-v1.appspot.com",
    messagingSenderId: "566683484323",
    appId: "1:566683484323:web:428acc156fbff4b482ff16",
    measurementId: "G-8L5ZBF75KZ"
  },
  GA_TRACKING_ID:'G-X10Y2D07SH'
};
