<div class="px-3 m-auto main-box mw-75">
    <div class="title">Start your journey as a donor today.</div>
    <form class="form" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="d-flex changeForMobile">
            <div class="form-group w-100 p-1">
                <label>Name</label>
                <input type="text" class="form-control" formControlName="name">
                <span class="text-danger"
                    *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required">
                    Name is required
                </span>
                <span class="text-danger"
                    *ngIf="registerFormControl.name.touched && registerFormControl.name.errors?.pattern">
                    Enter a valid name.
                </span>
            </div>
            <div class="form-group w-100 p-1">
                <label>DOB</label>
                <input type="date" (change)="dateChange($event.target.value)" [max]="maxDate" class="form-control" formControlName="dob">
                <span class="text-danger"
                    *ngIf="(registerFormControl.dob.touched || submitted) && registerFormControl.dob.errors?.required">
                    DOB is required
                </span>
                <span class="text-danger" *ngIf="dateError">DOB is invalid</span>
            </div>
        </div>
        <div class="d-flex changeForMobile">
            <div class="form-group w-100 p-1">
                <label>Blood Group</label>
                <select formControlName="bloodType" class="form-control">
                    <option *ngFor="let bloodType of bloodGroups" value={{bloodType}}>{{bloodType}}</option>
                  </select>
                <span class="text-danger"
                    *ngIf="(registerFormControl.bloodType.touched || submitted) && registerFormControl.bloodType.errors?.required">
                    Blood Group is required
                </span>
                <span class="text-danger"
                    *ngIf="registerFormControl.bloodType.touched && registerFormControl.bloodType.errors?.bloodTypeNotAvailable">
                    Blood Group is not available
                </span>
            </div>
    
            <div class="form-group w-100 p-1">
                <label>Phone</label>
                <input name="phone" type="text" min='0' pattern="[0-9 ]{10}" (input)='debug($event)' max="9999999999" class="form-control" formControlName="phone">
                <span class="text-danger"
                    *ngIf="(registerFormControl.phone.touched || submitted) && registerFormControl.phone.errors?.required">
                    Phone is required
                </span>
                <span class="text-danger"
                    *ngIf="registerFormControl.phone.touched &&( registerFormControl.phone.errors?.minlength || registerFormControl.phone.errors?.pattern)">
                    Enter a valid phone address
                </span>
                <span class="text-danger"
                *ngIf="existingPhoneError">
                Phone number is already registered.Please try with other Phone number.
                </span>
            </div>
        </div>
        
        <div class="d-flex changeForMobile">
            <div class="form-group w-100 p-1">
                <label>Email</label>
                <input name="email" type="text" class="form-control" formControlName="email" (input)='debug($event)'>
                <span class="text-danger"
                *ngIf="(registerFormControl.email.touched || submitted) && registerFormControl.email.errors?.required">
                Email is required
                </span>
                <span class="text-danger"
                *ngIf="registerFormControl.email.touched && (registerFormControl.email.errors?.email || registerFormControl.email.errors?.pattern)">
                Enter a valid email address
                </span>
                <span class="text-danger"
                *ngIf="existingEmailError">
                Email address is already registered.Please try with other email id.
                </span>
            </div>
    
            <div class="form-group w-100 p-1">
                <label>PIN code</label>
                <input type="number" name="ZIP" (change)='debug($event)' class="form-control" formControlName="zipCode">
                <span class="text-danger"
                    *ngIf="(registerFormControl.zipCode.touched || submitted) && registerFormControl.zipCode.errors?.required">
                    PIN code is required
                </span>
                <span class="text-danger"
                    *ngIf="registerFormControl.zipCode.touched && ( registerFormControl.zipCode.errors?.minlength || registerFormControl.zipCode.errors?.pattern || zipCodeError)">
                    Enter a valid PIN code address
                </span>
                <div id="place" class="text-white"></div>
            </div>
        </div>



        
        <div class="form-group flex-wrap d-flex justify-content-center mt-3" >
            <button [disabled]="submitButtonValidation" style="width: 75%;" type="submit" class="btn btn-success">Register</button>
            <div class="termDays">By submitting you agree to our terms & conditions and privacy policy.</div>
        </div>

<div *ngIf="showLoader">
    <div class="modal-backdrop"  style="opacity: 0.5;"></div>
    <div class="d-block modal">
        <div class="loading modal-dialog" style="--size: 100px;">
            <div class="circle"><p [style.--loadingtext]="loadingText"></p></div>
            <div class="item">
              <div class="dot"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
            <div class="item" style="animation-delay: -1s;">
              <div class="dot" style="animation-delay: -1s;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
            <div class="item" style="animation-delay: -2s;">
              <div class="dot" style="animation-delay: -2s;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
        </div>
</div>

</div>
    </form>

</div>