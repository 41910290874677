<div id="accordion" class="px-3 m-auto">
  <div class="title">Frequently Asked Questions</div>
  <div class="card">
      <div class="card-header" id="faqhead1">
          <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq1"
          aria-expanded="true" aria-controls="faq1">What is the eligibility for blood donation?</a>
      </div>

      <div id="faq1" class="collapse" aria-labelledby="faqhead1" data-parent="#faq">
          <div class="card-body">
            Must be between 18-65, weigh over 50kgs, and have normal blood pressure, hemoglobin, and hemoglobin levels.
          </div>
      </div>
  </div>
  <div class="card">
      <div class="card-header" id="faqhead2">
          <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq2"
          aria-expanded="true" aria-controls="faq2">What is the average time it takes to donate blood?</a>
      </div>

      <div id="faq2" class="collapse" aria-labelledby="faqhead2" data-parent="#faq">
          <div class="card-body">The process of donating blood itself can take between 8 and 10 minutes, but the entire process can take between 15 and 20 minutes.
          </div>
      </div>
  </div>
  <div class="card">
      <div class="card-header" id="faqhead3">
          <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq3"
          aria-expanded="true" aria-controls="faq3">Will it hurt when you insert the needle?</a>
      </div>

      <div id="faq3" class="collapse" aria-labelledby="faqhead3" data-parent="#faq">
          <div class="card-body">
            Only for a moment. Pinch the fleshy, soft underside of your arm. That pinch is similar to what you will feel when the needle is inserted.
          </div>
      </div>
  </div>

  <div class="card">
    <div class="card-header" id="faqhead4">
        <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq4"
        aria-expanded="true" aria-controls="faq4">How long will it take to replenish the pint of blood I donate?</a>
    </div>

    <div id="faq4" class="collapse" aria-labelledby="faqhead4" data-parent="#faq">
        <div class="card-body">
          The plasma from your donation is replaced within about 24 hours. Red cells need about four to six weeks for complete replacement. That’s why at least eight weeks are required between whole blood donations.
        </div>
    </div>
</div>


<div class="card">
  <div class="card-header" id="faqhead5">
      <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq5"
      aria-expanded="true" aria-controls="faq5">How often can I donate blood?</a>
  </div>

  <div id="faq5" class="collapse" aria-labelledby="faqhead5" data-parent="#faq">
      <div class="card-body">
        You must wait at least eight weeks (56 days) between donations of whole blood and 16 weeks (112 days) between Power Red donations. Whole blood donors can donate up to 6 times a year. Platelet apheresis donors may give every 7 days up to 24 times per year. Regulations are different for those giving blood for themselves (autologous donors).      </div>
  </div>
</div>

<div class="card">
  <div class="card-header" id="faqhead6">
      <a href="#" class="btn btn-header-link collapsed" data-toggle="collapse" data-target="#faq6"
      aria-expanded="true" aria-controls="faq6">What should I do after donating blood?</a>
  </div>

  <div id="faq6" class="collapse" aria-labelledby="faqhead6" data-parent="#faq">
      <div class="card-body">
        <ul>
          <li>Drink an extra four glasses (eight ounces each) of non-alcoholic liquids.</li>
          <li>Keep your bandage on and dry for the next five hours, and do not do heavy exercising or lifting.</li>
          <li>If the needle site starts to bleed, raise your arm straight up and press on the site until the bleeding stops.</li>
          <li>Because you could experience dizziness or loss of strength, use caution if you plan to do anything that could put you or others at risk of harm. For any hazardous occupation or hobby, follow applicable safety recommendations regarding your return to these activities following a blood donation.</li>
          <li>Eat healthy meals and consider adding&nbsp;iron-rich foods to your regular diet, or discuss taking an iron supplement with your health care provider, to replace the iron lost with blood donation.</li>
          <li><strong>If you get a bruise</strong>: &nbsp;Apply ice to the area intermittently for 10-15 minutes during the first 24 hours. Thereafter, apply warm, moist heat to the area intermittently for 10-15 minutes. A rainbow of colors may occur for about 10 days.</li>
          <li><strong>If you get dizzy or lightheaded</strong>: &nbsp;Stop what you are doing, lie down, and raise your feet until the feeling passes and you feel well enough to safely resume activities.</li>
          <li><strong>And remember to enjoy the feeling of knowing you have helped save lives!</strong></li>
          <li><strong>Schedule your next appointment</strong>.</li>
      </ul>
          </div>
  </div>
</div>

</div>