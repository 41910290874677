import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import{FirestoreDBService} from 'src/app/services/firestore-db.service'

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loadingText = 'textLoad 2s linear infinite';
  submitted = false;
  showLoader =false;
  constructor(
    private fb: FormBuilder,private firestoreDBservice:FirestoreDBService) { }

  ngOnInit(): void {
      //Grouping the form
      this.loginForm = this.fb.group({
        email: ['', [Validators.required, Validators.email,Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")]],
        password: ['', [Validators.required]]
      });
  }

  async onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }
    this.showLoader =true;
    // let userCheck = await this.firestoreDBservice.getData('User Data','email',this.loginForm.value.email);
    // if(userCheck.length == 0){
    //   this.showLoader =false;
    //  return alert('Invalid Credential');
    // }
    await this.firestoreDBservice.SignIn(this.loginForm.value.email,this.loginForm.value.password)
    .then(async res => {
      console.log('Youre in!');
      this.showLoader =false;
      await this.firestoreDBservice.isloggedin();
      })
      .catch(err => {
      console.log('Something went wrong:',err.message);
      this.showLoader =false;
      return alert(err.message);
      });
  }
   //FormControl for the form
   get loginFormControl() {
    return this.loginForm.controls;
  }

}
