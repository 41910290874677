import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AngularFireAuth } from "@angular/fire/auth";
import { map } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthguardGuard implements CanActivate {
  constructor(private angularFireAuth: AngularFireAuth, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.angularFireAuth.authState.pipe(map((user)=>{
        if(user) {
          return true;
        }
        this.router.navigate(['/login'])
        return false;
      }))
  }
  
}
@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private angularFireAuth: AngularFireAuth, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.angularFireAuth.authState.pipe(map((user)=>{
        if(user) {
          this.router.navigate(['/dashboard'])
          return false;
        }
        return true;
      }))

  }
}
