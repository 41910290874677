
<div class="h-100 bg-dark d-flex flex-wrap">
    <div class="w-50 d-flex justify-content-center">
        <img class="logo-pic"   src="../../assets/images/logo.svg"
    alt="  Logo">
    </div>
    <div class="px-3 m-auto w-50">
        <div class="title">Welcome back</div>
        <div class="content">Welcome back! Please enter your details.</div>
        <form class="form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <div>
                <div class="form-group w-100 p-1">
                    <label>Email</label>
                    <input name="email" type="text" class="form-control" formControlName="email">
                    <span class="text-danger"
                    *ngIf="(loginFormControl.email.touched || submitted) && loginFormControl.email.errors?.required">
                    Enter you email
                    </span>
                    <span class="text-danger"
                    *ngIf="loginFormControl.email.touched && (loginFormControl.email.errors?.email || loginFormControl.email.errors?.pattern)">
                    Enter a valid email address
                    </span>
                </div>
                <div class="form-group w-100 p-1">
                    <label>Password</label>
                    <input name="password" type="text" class="form-control" formControlName="password" >
                    <span class="text-danger"
                    *ngIf="(loginFormControl.password.touched || submitted) && loginFormControl.password.errors?.required">
                    Enter your Password
                    </span>
                    
                </div>
            </div>
            
            <div class="form-group flex-wrap d-flex justify-content-center mt-3" >
                <button [disabled]="loginForm.invalid" style="width: 75%;" type="submit" class="btn btn-success">Login</button>
            </div>
    

        </form>
    
    </div>
</div>

<div *ngIf="showLoader">
    <div class="modal-backdrop"  style="opacity: 0.5;"></div>
    <div class="d-block modal">
        <div class="loading modal-dialog" style="--size: 100px;">
            <div class="circle"><p [style.--loadingtext]="loadingText"></p></div>
            <div class="item">
              <div class="dot"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
            <div class="item" style="animation-delay: -1s;">
              <div class="dot" style="animation-delay: -1s;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
            <div class="item" style="animation-delay: -2s;">
              <div class="dot" style="animation-delay: -2s;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#b22222" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg></div>
            </div>
        </div>
</div>

</div>